import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { SearchBarProvider } from './contexts/SearchBarContext';

function App() {
  return (
    <Routes>
      <Route path="/" element={<SearchBarProvider><Home /></SearchBarProvider>} />
      <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
