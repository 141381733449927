import React from 'react'
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon'
import { useSearchBar } from '../../contexts/SearchBarContext';
import './index.css';
import formatter from '../../utils/formatter';

const SearchBar: React.FC = () => {
  const { searchLocation, setsearchLocation } = useSearchBar();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputRef.current) {
      let searchLocation = formatter.formatLocationName(inputRef.current.value);
      setsearchLocation(searchLocation);
    }
  }

  return (
    <form className='flex justify-between w-72' onSubmit={handleSubmit}>
      <input type="search" name="search" placeholder='Pesquise a sua localização' ref={inputRef} defaultValue={searchLocation}
        className='w-60 border border-sky-500 text-sky-900 rounded px-4 py-2 focus:outline-none focus:ring-1 focus:ring-sky-500 hover:ring-1 hover:ring-sky-500'
      />
      <button type='submit' className='bg-sky-500 rounded px-2 py-2 focus:outline-none focus:ring-1 focus:ring-sky-500 hover:ring-1 hover:ring-sky-500' aria-label="Pesquisar">
        <MagnifyingGlassIcon className="h-6 w-6 text-white" />
      </button>
    </form>
  )
}

export default SearchBar