import React from 'react'
import { useSearchBar } from '../contexts/SearchBarContext'
import SearchBar from '../components/SearchBar'
import WeatherWidget from '../components/WeatherWidget'

const Home: React.FC = () => {
    const { searchLocation } = useSearchBar();

    return (
        <div className="container mx-auto my-4 px-4 flex flex-col gap-4 items-center justify-center">
            <h1 className="text-4xl text-center text-sky-600 font-bold md:text-5xl">
                Bem-vindo ao Clima.log.br
            </h1>
            <p className="text-gray-600 text-center md:text-lg">
                Sua fonte confiável de informações climáticas.
            </p>
            <SearchBar />
            {searchLocation ? <WeatherWidget location={searchLocation} /> : <WeatherWidget location='São Paulo' />}
        </div>
    )
}

export default Home