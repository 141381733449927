import React, { useEffect, useState } from 'react'
import { WeatherData } from '../../types/WeatherData'
import WeatherWidgetSkeleton from '../WeatherWidgetSkeleton'
import { ReactComponent as MoonSvg } from '../../assets/svgs/moon.svg'
import { ReactComponent as SunSvg } from '../../assets/svgs/sun.svg'
import { ReactComponent as CloudSvg } from '../../assets/svgs/cloud.svg'
import { ReactComponent as RainySvg } from '../../assets/svgs/rainy.svg'
import { ReactComponent as ThunderStormSvg } from '../../assets/svgs/thunderstorm.svg'
import formatter from '../../utils/formatter';
import { getWeatherData } from '../../utils/weatherServices';

interface WeatherWidgetProps {
    location: string;
}

const thunderstormIds = [200, 201, 202, 210, 211, 212, 221, 230, 231, 232];
const rainIds = [300, 301, 302, 310, 311, 312, 313, 314, 321, 500, 501, 502, 503, 504, 511, 520, 521, 522, 531];
const cloudIds = [801, 802, 803, 804];

const WeatherWidget: React.FC<WeatherWidgetProps> = ({ location }) => {
    const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const data = await getWeatherData(location);
            if (!data) {
                setWeatherData(null);
                setIsLoading(false);
                return
            }
            setWeatherData(data as WeatherData);
            setIsLoading(false);
        }

        fetchData();
    }, [location]);

    const isDay = (weatherData: WeatherData) => {
        const currentTimeUTC = new Date().getTime() / 1000;

        const localTime = currentTimeUTC + weatherData.timezone;

        if (localTime > weatherData.sys.sunrise && localTime < weatherData.sys.sunset) {
            return <SunSvg className='fill-amber-500 w-32 h-32 drop-shadow-[0_0_20px_rgba(252,165,0,0.8)] absolute bottom-[275px]' />;
        } else {
            return <MoonSvg className='fill-white w-32 h-32 drop-shadow-[0_0_20px_rgba(252,252,252,0.8)] absolute bottom-[285px]' />;
        }
    };

    const getWeatherSvg = (weatherData: WeatherData) => {
        let cloudSize = weatherData.clouds.all;
        let weatherId = weatherData.weather[0].id;
        let isThundering = thunderstormIds.includes(weatherId);
        let isRaining = rainIds.includes(weatherId);
        let isCloudy = cloudIds.includes(weatherId);

        if (isThundering) {
            return <ThunderStormSvg className='absolute bottom-[270px] right-[120px] fill-sky-200 w-20 h-20 drop-shadow-[0_0_20px_rgba(173,216,230,0.8)]' />;
        } else if (isRaining) {
            return <RainySvg className='absolute bottom-[270px] right-[120px] fill-sky-200 w-20 h-20 drop-shadow-[0_0_20px_rgba(173,216,230,0.8)]' />;
        } else if (isCloudy || cloudSize > 50) {
            return <CloudSvg className='absolute bottom-[270px] right-[120px] fill-sky-200 w-20 h-20 drop-shadow-[0_0_20px_rgba(173,216,230,0.8)]' />;
        }
    };

    if (isLoading) return <WeatherWidgetSkeleton />;

    if (!weatherData) return <p>Dados não disponíveis</p>;

    return (
        <div className='antialiased relative mt-24 mb-9 flex justify-center w-60 h-80 rounded-xl p-6 bg-gradient-to-b from-gray-800 to-gray-600 text-white shadow-2xl'>
            {isDay(weatherData)}
            {getWeatherSvg(weatherData)}
            <div className='flex justify-center flex-col w-full mt-4'>
                <div id='temperature' className='flex justify-center text-6xl font-bold text-center ml-4'>{Math.round(weatherData.main.temp)}<span className='text-base mt-1.5'>ºC</span></div>
                <div id='weather' className='flex justify-center'>{formatter.capitalizeFirstLetter(weatherData.weather[0].description)}</div>
                <div id='high' className='grid grid-cols-2 mt-3 justify-items-center items-center text-sm'><span>Max:</span><span>{Math.round(weatherData.main.temp_max)}º</span></div>
                <div id='low' className='grid grid-cols-2 justify-items-center items-center text-sm'><span>Min:</span><span>{Math.round(weatherData.main.temp_min)}º</span></div>
                <div id='feels-like' className='grid grid-cols-2 justify-items-center items-center text-sm'><span>Sensação:</span><span>{Math.round(weatherData.main.feels_like)}º</span></div>
                <div id='humidity' className='grid grid-cols-2 justify-items-center items-center text-sm'><span>Humidade:</span><span>{weatherData.main.humidity}%</span></div>
                <div id='wind' className='grid grid-cols-2 justify-items-center items-center text-sm'><span>Ventos:</span><span>{Math.round(weatherData.wind.speed)}km/h</span></div>
                <div id='location' className='flex justify-center mt-3 text-3xl font-bold'>{weatherData.name}</div>
            </div>
        </div>
    )
}

export default WeatherWidget