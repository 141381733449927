import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='container antialiased mx-auto my-16 px-4 flex flex-col gap-8'>
      <div className='flex flex-col gap-2'>
        <h1 className='text-4xl text-center text-sky-600 font-bold mb-4 md:text-5xl'>Política de Privacidade</h1>
        <p className='text-gray-600 md:text-lg'>O clima.log.br e seus subdomínios sabem o quanto é importante para você conhecer e estar seguro sobre a utilização dos seus dados pessoais. Por isso, nos preocupamos em esclarecer e divulgar nossa política de utilização dessas informações. Assim, você poderá entender melhor quais informações obtemos e como as utilizamos. Dedique alguns minutos do seu tempo para ler essa política e aproveitar de forma consciente e segura tudo o que o clima.log.br tem para oferecer. Para assegurar regras claras e precisas, clima.log.br pode alterar essa política de tempos em tempos, e sendo assim, recomenda sua leitura periodicamente.</p>
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='text-3xl text-sky-500 font-bold md:text-4xl'>O que são "cookies" e qual sua utilidade</h2>
        <p className='text-gray-600 md:text-lg'>Cookies são pequenos arquivos de texto enviados ao seu computador. Os cookies são armazenados no seu computador. Estes pequenos arquivos servem para reconhecer, acompanhar e armazenar a navegação do usuário na Internet.</p>

        <p className='text-gray-600 md:text-lg'>O uso de cookies para acompanhar e armazenar informações possibilitará o clima.log.br oferecer um serviço mais personalizado, de acordo com as características e interesses de seus usuários, possibilitando, inclusive, a oferta de conteúdo e publicidade específicos para cada pessoa, beneficiando a experiência do usuário na Internet.</p>

        <p className='text-gray-600 md:text-lg'>Em geral, os cookies são utilizados para:</p>
        <ul className='list-disc ml-8 text-gray-600 md:text-lg'>
          <li>Proporcionar serviços diferenciados, lembrando quem você é e quais são os seus hábitos de navegação.</li>
          <li>Medir certos padrões de navegação, mapeando quais áreas do site clima.log.br você visitou e seus hábitos de visita como um todo.</li>
          <li>Facilitar e agilizar o preenchimento de formulários.</li>
        </ul>
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='text-3xl text-sky-500 font-bold md:text-4xl'>Como serão utilizadas estas informações?</h2>
        <p className='text-gray-600 md:text-lg'>As informações coletadas pelo clima.log.br serão utilizadas para personalizar o conteúdo e/ou serviços disponibilizados. Isso inclui serviços de personalização e comunicações interativas.</p>

        <p className='text-gray-600 md:text-lg'>As informações coletadas poderão ser utilizadas para direcionamento de campanhas publicitárias de uma forma geral.</p>
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='text-3xl text-sky-500 font-bold md:text-4xl'>Com quem suas informações podem ser compartilhadas?</h2>
        <p className='text-gray-600 md:text-lg'>O clima.log.br não tem como prática a divulgação de informações que possam identificá-lo, salvo quando autorizado por você ou em circunstâncias previstas nesta presente política.</p>
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='text-3xl text-sky-500 font-bold md:text-4xl'>O que mais você precisa saber sobre sua privacidade on-line</h2>
        <p className='text-gray-600 md:text-lg'>Em última análise, você é o responsável pela manutenção de sua privacidade e pelo sigilo de suas informações pessoais.</p>
      </div>
      <h2 className='text-3xl text-sky-500 font-bold md:text-4xl'>Isenção de responsabilidade</h2>
      <p className='text-gray-600 md:text-lg'>Hyperlinks externos não constituem endosso pelo clima.log.br dos sites/ambientes vinculados ou as informações, produtos ou serviços ali contidos.</p>
      <div className='flex flex-col gap-2'>
        <h2 className='text-3xl text-sky-500 font-bold md:text-4xl'>Aceitação</h2>
        <p className='text-gray-600 md:text-lg'>Ao assistir e/ou utilizar o Conteúdo de qualquer forma, Você expressa sua aceitação plena e sem reservas aos Termos Gerais tal como vigentes naquele instante, para todos os fins de direito.</p>
      </div>
      <div className='flex flex-col gap-2'>
        <h2 className='text-3xl text-sky-500 font-bold md:text-4xl'>Condições gerais</h2>
        <p className='text-gray-600 md:text-lg'>O não exercício pelo clima.log.br de quaisquer direitos ou faculdades que lhe sejam conferidos por estes Termos Gerais ou pela legislação brasileira não importará na renúncia aos seus direitos, novação ou alteração de itens e cláusulas deste instrumento.</p>
      </div>
    </div>
  )
}

export default PrivacyPolicy