import React, { ReactNode, createContext, useContext, useState } from 'react'

interface SearchBarContextType {
    searchLocation: string;
    setsearchLocation: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBarContext = createContext<SearchBarContextType | undefined>(undefined);

interface SearchBarProviderProps {
    children: ReactNode;
}

export const SearchBarProvider: React.FC<SearchBarProviderProps> = ({ children }) => {
    const [searchLocation, setsearchLocation] = useState<string>('');

    return (
        <SearchBarContext.Provider value={{ searchLocation, setsearchLocation }}>
            {children}
        </SearchBarContext.Provider>
    );
};

export const useSearchBar = () => {
    const context = useContext(SearchBarContext);
    if (context === undefined) {
        throw new Error('useSearchBar must be used within a SearchBarProvider');
    }
    return context;
};
