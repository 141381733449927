import React from 'react'

const WeatherWidgetSkeleton:React.FC = () => {
    return (
        <div className="w-72 rounded-lg p-6 bg-slate-800 shadow">
            <div className="animate-pulse flex flex-col items-center justify-center">
                <div className="rounded-full bg-slate-700 w-20 h-20 mt-3"></div>
                <div className="w-20 h-12 bg-slate-700 rounded mt-4"></div>
                <div className="w-32 h-2 bg-slate-700 rounded mt-5"></div>
                <div className="w-40 h-2 bg-slate-700 rounded mt-4"></div>
                <div className="w-36 h-2 bg-slate-700 rounded mt-4"></div>
                <div className="w-40 h-12 bg-slate-700 rounded mt-5"></div>
                <div className="w-32 h-2 bg-slate-700 rounded mt-4"></div>
            </div>
        </div>
    )
}

export default WeatherWidgetSkeleton