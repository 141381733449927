import axios from "axios";
import { WeatherData } from "../types/WeatherData";

const getWeatherDataOnApi = async (location: string): Promise<WeatherData | false> => {
    let apiKey = process.env.REACT_APP_API_KEY;

    try {
        const response = await axios.get<WeatherData>(`https://api.openweathermap.org/data/2.5/weather?appid=${apiKey}&lang=pt_br&units=metric&q=${location}`);
        return response.data;
    } catch (error) {
        //console.error("Erro ao buscar dados:", error);
        return false;
    }
};

const getWeatherData = async (location: string) => {
    const weatherData = await getWeatherDataOnApi(location);
    if (weatherData) {
        return weatherData;
    }

    return false;
}

export { getWeatherData };